<template>
  <div
    id="notification-general"
    class="cursor-pointer"
  >
    <b-row
      v-if="dataEmail.length"
      class=""
    >
      <b-col>
        <b-button
          class="mb-25"
          variant="danger"
          @click="hideAllMail"
        >
          Ẩn tất cả thông báo
        </b-button>
      </b-col>
      <b-col>
        <b-button
          :badge="12"
          class="w-100 mb-25 position-relative"
          variant="primary"
          @click="$router.push({name: 'apps-email'})"
        >
          Xem tất cả
          <b-badge
            v-if="dataCountNotify"
            class="bg-danger badge badge-up badge-pill"
          >
            {{ dataCountNotify }}
          </b-badge>
        </b-button>
      </b-col>

    </b-row>

    <vue-perfect-scrollbar
      ref="scroll-notify"
      :settings="perfectScrollbarSettings"
      class="content-notification"
      tagname="div"
    >

      <div
        v-for="(notification, index) in dataEmail"
        :key="notification.id"
        class="animation-back-in-right"
      >
        <div
          class="item-notification  rounded shadow-lg animation-back-out-right"
        >
          <div
            class="d-flex align-items-center justify-content-between"
          >
            <b-media
              class="align-items-center"
              @click="notificationClick(notification)"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  variant="primary"
                  :src="$serverfile + notification.avatar"
                  :text="notification.fullName"
                />
              </template>
              <p
                class="font-weight-bold mb-0 text-truncate"
                style="width: 300px"
              >{{ notification.content }}</p>
              <small>{{ getTimeLeft(notification.sentDate) }}</small>
            </b-media>

          </div>
        </div>

      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>

import {
  BMedia, BAvatar, BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import {
  mapActions, mapState, mapMutations,
  mapGetters,
} from 'vuex'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  getTimeLeft,
} from '@core/utils/filter'
import ConstantsApi from '@/views/email/constant/ConstantsApi'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBadge,
    VuePerfectScrollbar,
  },
  props: {
    notifications: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      getTimeLeft,
      perfectScrollbarSettings: {
        maxScrollbarLength: 120,
      },
      timer: {},
      dataEmail: [],
    }
  },
  created() {
    this.fetchEmailInbox()
  },
  computed: {
    ...mapGetters('app', ['dataCountNotify']),
  },
  methods: {

    async fetchEmailInbox() {
      const res = await axiosApiInstance.get(ConstantsApi.API_GET_INBOX, {
        params: {
          pageNumber: 1,
          pageSize: 3,
        },
      })
      this.dataEmail = res?.data?.data?.pageLists
    },

    async notificationClick(notification, index) {
      this.hideAllMail()
      if (this.$route.name === 'apps-email') {
        eventBus.$emit('getDetailEmail', notification)
        return
      }
      this.$router.push({ name: 'apps-email', query: { idMail: notification.inboxId } })
    },
    hideAllMail() {
      this.$emit('hide')
    },

  },
}
</script>

<style lang="scss" scoped>
#notification-general {
  position: fixed;
  bottom: 10px;
  right: 70px;
  z-index: 9999999;
  background-color: unset;
  .content-notification {
    width: 400px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .item-notification {
      align-content: center;
      align-items: center;
      margin-top: 8px;
      background: white;
      padding: 10px 18px 10px 18px;
    }
  }
  .animation-back-out-right {
    animation-name: backOutRight;
    animation-delay: 59s;
    animation-duration: 1s;
    opacity: 1;
  }
  @keyframes backOutRight {
    0%   {transform: translateX(0); opacity: 1;}
    100% {transform: translateX(+100%); opacity: 0.3;}
  }
  .animation-back-in-right {
    animation-name: backInRight;
    animation-duration: 1s;
    opacity: 1;
  }

  @keyframes backInRight {
  0%   { transform: translateX(100%); opacity: 0.3;}
  100% {transform: translateX(0); opacity: 1;}
  }

}
</style>
