/* eslint-disable no-shadow */
/* eslint-disable consistent-return */

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constant/ConstantsApi'

const signalR = require('@microsoft/signalr')

const state = () => ({
  listNotification: [],
  notificationGeneral: [],
  notifications: [],
  urlQueryNotify: {
    notifyStatus: null,
    pageNumber: 1,
    pageSize: 10,
  },
  totalNotification: 0,
  totalRecord: 0,
  totalNotificationUnread: 0,
})

const getters = {

}

const actions = {
  async getListNotification({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.API_LIST_NOTIFICATION, { params: payload.params })
      if (data) {
        commit('setListNotification', { newData: data.data.pageLists, isLoadMore: payload.isLoadMore })
        commit('setTotalNotifications', data.data.totalRecord)
      }
    } catch {
      //
    }
  },

  async getListNotificationViewAll({ commit, state }) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.API_LIST_NOTIFICATION, { params: state.urlQueryNotify })
      if (data) {
        commit('setListNotificationViewAll', data.data.pageLists)
        commit('setTotalNotificationViewAll', data.data.totalRecord)
      }
    } catch {
      //
    }
  },

  async getNotificationLogin() {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.API_LIST_NOTIFICATION, {
        params: {
          notifyStatus: null,
          pageNumber: 1,
          pageSize: 5,
        },
      })
      return data?.data
    } catch (error) {
      return error
    }
  },

  async getCountNotificationUnread({ commit }) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.COUNT_NOTIFICATION_UNREAD)
      commit('setNotificationUnread', data)
    } catch (error) {
      //
    }
  },

  async seenNotification({ commit }, payload) {
    try {
      const res = axiosApiInstance.get(`${ConstantsApi.SEEN_NOTIFICATION}${payload.id}`)
      return res
    } catch (error) {
      return error
    }
  },

  getNotificationGeneral({ commit }, data) {
    commit('setNotificationGeneral', data)
  },
  hideNotification({ commit }, data) {
    commit('splitNotification', data)
  },

  signalRConnection({ commit, dispatch }, data) {
    try {
      let userData = localStorage.getItem('userData')
      if (userData) {
        userData = JSON.parse(userData)
        const url = `${process.env.VUE_APP_BASE_API}hubs/lisahub?uid=${userData.userId}&mid=${userData.unitUserId}`
        this.connection = new signalR.HubConnectionBuilder() // Connect to a hub
          .withUrl(url, {
          // eslint-disable-next-line no-bitwise
            transport: signalR.HttpTransportType.Websocket,
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT',
            'Access-Control-Allow-Headers': 'Content-Type',
          })
          .withAutomaticReconnect()
          .build()
        this.connection
          .start()
          .then(() => {
            this.connection.on('NotifySystem', mes => {
              dispatch('getListNotification', {
                params: {
                  pageSize: 5,
                  pageNumber: 1,
                },
                isLoadMore: false,
              })
              dispatch('getListNotificationViewAll', {
                pageSize: 10,
                pageNumber: 1,
              })
              dispatch('getNotificationGeneral', { isLoadMore: true, item: mes })
              dispatch('getCountNotificationUnread')
            })
          })
          .catch(() => {
          })
      }
    } catch (e) {
      const a = e
      throw e
    }
  },

  async handleReadAllNotify({ commit, dispatch }, data) {
    try {
      const res = await axiosApiInstance.post(ConstantsApi.READ_ALL_NOTIFICATION)
      if (res.status === 200) {
        commit('setNotificationUnread', 0)
      }
    } catch (error) {
      return error
    }
  },
}
const mutations = {
  setListNotification(state, data) {
    if (!data.isLoadMore) {
      state.listNotification = data.newData
    } else {
      state.listNotification = [...state.listNotification, ...data.newData]
    }
  },

  setNotificationUnread(state, data) {
    if (data >= 0) { state.totalNotificationUnread = data }
  },

  setTotalNotifications(state, data) {
    state.totalNotification = data
  },

  setListNotificationViewAll(state, data) {
    state.notifications = data
  },
  setTotalNotificationViewAll(state, data) {
    state.totalRecord = data
  },

  setNotificationGeneral(state, data) {
    if (data.isLoadMore) {
      state.notificationGeneral.push(data.item)
    } else {
      state.notificationGeneral = []
    }
  },

  setUrlQueryNotifyAll(state, data) {
    state.urlQueryNotify = data
  },

  splitNotification(state, data) {
    state.notificationGeneral.splice(0, 1)
  },

  clearNotify(state, index) {
    state.notificationGeneral.splice(index, 1)
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
