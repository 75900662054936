export default [
  // danh sách quản lý tin tức
  {
    path: '/danh-nghiep',
    name: 'enterprise-home',
    component: () => import('../../home/Enterprise.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/chi-tiet-doanh-nghiep/:id',
    name: 'enterprise-detail-home',
    component: () => import('../../home/EnterpriseDetail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tai-lieu',
    name: 'document-home',
    component: () => import('../../home/LegalDocument.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/doanh-nghiep-vi-pham',
    name: 'violation-home',
    component: () => import('../../home/BussinessViolations.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/hom-thu-gop-y',
    name: 'feedback-home',
    component: () => import('../../home/FeedBack.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/danh-sach-kiem-dinh-vien',
    name: 'list-inspector-home',
    component: () => import('../../home/ListInspector.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tin-tuc',
    name: 'news-home',
    component: () => import('../../home/News.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/chi-tiet-tin-tuc/:id',
    name: 'news-detail-home',
    component: () => import('../../home/NewsDetail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-nguoi-lao-dong',
    name: 'worker-home',
    component: () => import('../../home/Worker.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-giay-phep',
    name: 'license-home',
    component: () => import('../../home/License.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-thiet-bi',
    name: 'devices-home',
    component: () => import('../../home/Devices.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-tinh-hinh-nop-bao-cao',
    name: 'report-home',
    component: () => import('../../home/Report.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-danh-sach-doanh-nghiep-nop-bao-cao-dinh-ky',
    name: 'submit-reports-home',
    component: () => import('../../home/SubmitReport.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/tra-cuu-thong-tin-thanh-tra-kiem-tra',
    name: 'supervise',
    component: () => import('../../home/Supervise.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/danh-sach-cac-doanh-nghiep-da-tu-cong-bo-du-dieu-kien-huan-luyen-hang-A',
    name: 'condition-department-home-page',
    component: () => import('../../home/ConditionDepartment.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]
