export default [

  // cài đặt trang chủ
  {
    path: '/cai-dat',
    name: 'setting',
    component: () => import('@/views/settings/setting/pages/List.vue'),
    meta: {
      parent: 'setting',
      pageTitle: 'Cài đặt',
      breadcrumb: [
        {
          text: 'Cài đặt',
        },
        {
          text: 'Cài đặt ',
          active: true,
        },
      ],
    },
  },

  // Cài đặt menu
  {
    path: '/chinh-sua-menu',
    name: 'custom-menu',
    component: () => import('@/views/settings/custom-menu/pages/Menu.vue'),
    meta: {
      parent: 'custom-menu',
      pageTitle: 'Sắp xếp menu',
      breadcrumb: [
        {
          text: 'Cài đặt',
        },
        {
          text: 'Sắp xếp menu',
          active: true,
        },
      ],
    },
  },
  // Cài đặt menu
  {
    path: '/chinh-sua-menu/keo-tha',
    name: 'custom-menu',
    component: () => import('@/views/settings/custom-menu/pages/Menu.vue'),
    meta: {
      parent: 'custom-menu',
      pageTitle: 'Sắp xếp menu',
      breadcrumb: [
        {
          text: 'Cài đặt',
        },
        {
          text: 'Sắp xếp menu',
          active: true,
        },
      ],
    },
  },

]
