<template>
  <div>
    <NotificationEmail
      v-if="isMail"
      @hide="hideMail"
    />

    <div
      v-else-if="notificationGeneral.length"
      id="notification-general"
      class="cursor-pointer"
    >
      <b-button
        class="w-100 mb-25"
        variant="primary"
        @click="hideAllNotification"
      >
        Ẩn tất cả thông báo
      </b-button>
      <vue-perfect-scrollbar
        ref="scroll-notify"
        :settings="perfectScrollbarSettings"
        class="content-notification"
        tagname="div"
      >

        <div
          v-for="(notification, index) in notificationGeneral"
          :key="notification.id"
          class="animation-back-in-right"
        >
          <div
            class="item-notification  rounded shadow-lg animation-back-out-right"
          >
            <div
              class="d-flex align-items-center justify-content-between"
            >
              <b-media
                class="align-items-center"
                @click="notificationClick(notification)"
              >
                <template #aside>
                  <b-avatar
                    size="32"
                    variant="primary"
                    :src="notification.avatar"
                    :text="notification.fullName"
                  />
                </template>
                <p
                  class="font-weight-bold mb-0"
                >{{ notification.content }}</p>
              </b-media>
              <div
                class=" ml-4"
                @click="hide(index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="18"
                />
              </div>
            </div>
          </div>

        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>

import {
  BMedia, BAvatar, BButton,
} from 'bootstrap-vue'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NotificationEmail from './NotificationEmail.vue'
import eventBus from '@/libs/event-bus'

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
    VuePerfectScrollbar,
    NotificationEmail,
  },
  props: {
    notifications: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 120,
      },
      timer: {},
      isMail: false,
    }
  },
  computed: {
    ...mapState('notification', ['notificationGeneral', 'listNotification']),
  },
  watch: {
    async notificationGeneral(val) {
      if (val.length) {
        const element = await document.querySelector('.content-notification')
        if (element) {
          this.$nextTick().then(() => {
            if (element.scrollTop !== null && element.scrollHeight !== null) {
              element.scrollTop = element.scrollHeight
            }
          })
        }
        this.timer[`timeHandle${val.length}`] = window.setTimeout(async () => {
          await this.hideNotification()
          clearTimeout(this.timer[`timeHandle${val.length}`])
        }, 5000)
        this.isMail = false
      }
    },
  },
  created() {
    eventBus.$on('showEmailNew', this.showEmailNew)
  },
  destroyed() {
    eventBus.$off('showEmailNew', this.showEmailNew)
  },
  methods: {
    ...mapMutations('notification', ['setListNotification', 'clearNotify']),
    ...mapActions('notification', ['getNotificationGeneral', 'getListNotification', 'seenNotification', 'hideNotification', 'getCountNotificationUnread']),
    showEmailNew() {
      this.isMail = true
      setTimeout(() => {
        this.isMail = false
      }, 60000)
    },
    hideMail() {
      this.isMail = false
    },
    async notificationClick(notification, index) {
      if (!notification.viewDate) {
        const res = await this.seenNotification({ id: notification.id })
        if (res.status === 200) { await this.getCountNotificationUnread() }
        const listNotify = this.listNotification
        listNotify.forEach(element => {
          if (element.id === notification.id) {
            element.viewDate = new Date()
          }
        })
        this.setListNotification({ isLoadMore: false, newData: listNotify })
      }
      if (this.$route.path !== notification.link) {
        this.$router.push(notification.link)
      }
      await this.hide(index)
    },
    async hide(index) {
      this.clearNotify(index)
    },
    hideAllNotification() {
      this.getNotificationGeneral({
        isLoadMore: false,
        item: null,
      })
    },

  },
}
</script>

<style lang="scss" scoped>
#notification-general {
  position: fixed;
  bottom: 10px;
  right: 70px;
  z-index: 9999999;
  background-color: unset;
  .content-notification {
    width: 400px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .item-notification {
      align-content: center;
      align-items: center;
      margin-top: 8px;
      background: white;
      padding: 10px 18px 10px 18px;
    }
  }
  .animation-back-out-right {
    animation-name: backOutRight;
    animation-delay: 4s;
    animation-duration: 1s;
    opacity: 1;
  }
  @keyframes backOutRight {
    0%   {transform: translateX(0); opacity: 1;}
    100% {transform: translateX(+100%); opacity: 0.3;}
  }
  .animation-back-in-right {
    animation-name: backInRight;
    animation-duration: 1s;
    opacity: 1;
  }

  @keyframes backInRight {
  0%   { transform: translateX(100%); opacity: 0.3;}
  100% {transform: translateX(0); opacity: 1;}
  }

}
</style>
